import * as React from 'react';

import { useEnvironment } from '@wix/yoshi-flow-editor';
import { useSettings } from '@wix/yoshi-flow-editor/tpa-settings/react';
import { SearchDocumentType } from '@wix/client-search-sdk';

import { useAppSettings } from '../../hooks';
import { hasImage, Thumbnail } from '../Thumbnail';
import { Title } from '../Title';
import { Price } from '../Price';
import { ILayoutItemProps } from '../Layout.types';
import { AddToCartButton } from '../AddToCartButton';
import settingsParams from '../../../settingsParams';

import { st, classes } from './GridLayoutItem.st.css';

const THUMBNAIL_WIDTH = {
  DESKTOP: 220,
  MOBILE: 130,
};

const getThumbnailWidth = (isMobile: boolean) =>
  isMobile ? THUMBNAIL_WIDTH.MOBILE : THUMBNAIL_WIDTH.DESKTOP;

const THUMBNAIL_HEIGHT = {
  DESKTOP: 220,
  MOBILE: 105,
};

const getThumbnailHeight = (isMobile: boolean) =>
  isMobile ? THUMBNAIL_HEIGHT.MOBILE : THUMBNAIL_HEIGHT.DESKTOP;

interface IGridLayoutItemProps extends ILayoutItemProps {
  onProductAddToCartClick: React.MouseEventHandler;
}

export const GridLayoutItem: React.FC<IGridLayoutItemProps> = ({
  item,
  onLinkClick,
  onProductAddToCartClick,
}) => {
  const { isMobile, isEditorX } = useEnvironment();
  const settings = useSettings();
  const appSettings = useAppSettings();

  const { price, image, title, url } = item;

  const showPrice = !!price && appSettings.isProductPriceVisible;

  const thumbnailWidth = getThumbnailWidth(isMobile);
  const thumbnailHeight = getThumbnailHeight(isMobile);

  return (
    <li
      data-hook="grid-layout-item"
      className={st(classes.root, {
        mobileView: isMobile,
        fluid: isEditorX,
      })}
    >
      {hasImage(image) && (
        <Thumbnail
          height={thumbnailHeight}
          image={image}
          maxResponsiveImageHeight={578}
          maxResponsiveImageWidth={578}
          onClick={(e) => onLinkClick?.(e, 'image')}
          title={title}
          url={url}
          width={thumbnailWidth}
        />
      )}
      <div className={classes.titleWrapper} dir="auto">
        <Title
          onClick={(e) => onLinkClick?.(e, 'title')}
          title={title}
          url={url}
        />
      </div>
      {showPrice && <Price price={item.price!} />}
      {item.documentType === SearchDocumentType.Products &&
        settings.get(settingsParams.isProductsAddToCartEnabled) && (
          <AddToCartButton
            onClick={onProductAddToCartClick}
            inStock={item.inStock!}
          />
        )}
    </li>
  );
};
