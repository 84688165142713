import React, { FC } from 'react';
import { useEnvironment, useTranslation } from '@wix/yoshi-flow-editor';

import { useFormatters } from '../../../hooks';
import { stripMarkTags } from '../../stripMarkTags';
import { IForumListItemProps } from '../ForumListItem';
import StatsBar from './StatsBar';
import { classes, st } from './ForumListCommentItem.st.css';

const ForumListCommentItem: FC<IForumListItemProps> = ({
  item,
  onLinkClick,
}) => {
  const { t } = useTranslation();
  const { isMobile, isEditorX } = useEnvironment();
  const { formatDate } = useFormatters();

  const styleStates = {
    mobileView: isMobile,
    fluid: isEditorX,
  };

  const isReply = !!item.parentId;

  return (
    <a
      href={item.url}
      className={st(classes.root, styleStates)}
      data-hook="item-title"
      title={stripMarkTags(item.title)}
      onClick={(e) => onLinkClick?.(e, 'title')}
    >
      <div className={classes.itemContainer}>
        <div
          className={classes.title}
          dangerouslySetInnerHTML={{ __html: item.title }}
        />
        <div className={classes.subTitle}>
          {t('searchResults.tabs.label.forums.inCategory', {
            category: item.categoryTitle,
          })}
        </div>

        <div className={classes.descriptionContainer}>
          {/* @TODO add member data, when available */}
          {/* @TODO add best answer badge, when available */}
          {item.createdDate && (
            <div className={classes.subTitle}>
              {formatDate(item.createdDate)}
            </div>
          )}
          <div
            className={classes.description}
            dangerouslySetInnerHTML={{ __html: item.description }}
          />
          <StatsBar
            containerClassName={classes.statsContainer}
            likes={item.likeCount}
            replies={!isReply ? item.totalComments : undefined}
          />
        </div>
      </div>
    </a>
  );
};

export default ForumListCommentItem;
