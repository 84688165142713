import React from 'react';

import { ISearchResultsControllerProps } from '../../platform/searchResultsControllerStore';
import { Widget } from '../Widget';
import { AppSettingsContext, SearchEnvironmentContext } from '../../contexts';
import { useHighlightStyle } from './useHighlightStyle';
import { IHostProps } from '@wix/yoshi-flow-editor';

export interface IWidgetRootProps extends ISearchResultsControllerProps {
  id: string;
  host: IHostProps;
}

export const WidgetRoot: React.FC<IWidgetRootProps> = React.memo((props) => {
  console.log('WidgetRoot:render', props); // eslint-disable-line

  const {
    apiErrorDetails,
    id,
    isDemoContent,
    locale,
    locationQuery,
    currency,
    onDocumentClick,
    onDocumentTypeChange,
    onPageChange,
    onProductAddToCart,
    onProductFacetsFilterChange,
    onProductFacetsFilterReset,
    onForumFacetsFilterChange,
    onForumFacetsFilterReset,
    onQuerySubmit,
    onSortChange,
    onScrollToWidget,
    productFacets,
    visibleDocumentTypes,
    scrollToWidget,
    forumFacets,
    searchSamples,
    searchRequest,
    searchRequestStatus,
    searchResponse,
    searchResponseTotals,
    searchResultsAbsoluteUrl,
    selectedSortOption,
    appSettings,
    viewMode,
    host,
  } = useHighlightStyle(props);

  return (
    <div className={id}>
      <AppSettingsContext.Provider value={appSettings}>
        <SearchEnvironmentContext.Provider
          value={{
            isDemoContent,
            viewMode,
            locale,
            currency,
          }}
        >
          <Widget
            apiErrorDetails={apiErrorDetails}
            visibleDocumentTypes={visibleDocumentTypes}
            locationQuery={locationQuery}
            onProductAddToCart={onProductAddToCart}
            onProductFacetsFilterChange={onProductFacetsFilterChange}
            onDocumentClick={onDocumentClick}
            onDocumentTypeChange={onDocumentTypeChange}
            onProductFacetsFilterReset={onProductFacetsFilterReset}
            onPageChange={onPageChange}
            onSortChange={onSortChange}
            onQuerySubmit={onQuerySubmit}
            onForumFacetsFilterChange={onForumFacetsFilterChange}
            onForumFacetsFilterReset={onForumFacetsFilterReset}
            searchRequest={searchRequest}
            searchRequestStatus={searchRequestStatus}
            searchResponse={searchResponse}
            searchResponseTotals={searchResponseTotals}
            searchResultsAbsoluteUrl={searchResultsAbsoluteUrl}
            searchSamples={searchSamples}
            selectedSortOption={selectedSortOption}
            forumFacets={forumFacets}
            productFacets={productFacets}
            host={host}
            scrollToWidget={scrollToWidget}
            onScrollToWidget={onScrollToWidget}
          />
        </SearchEnvironmentContext.Provider>
      </AppSettingsContext.Provider>
    </div>
  );
});
