import React from 'react';
import { useTranslation, useEnvironment } from '@wix/yoshi-flow-editor';
import { useSettings } from '@wix/yoshi-flow-editor/tpa-settings/react';
import { Tabs, TabsAlignment, TabsProps } from 'wix-ui-tpa';

import { ITab } from '../../types/types';
import settingsParams, { Alignment } from '../../../settingsParams';

import { st, classes } from './TabLine.st.css';

type TabLineProps = {
  activeTabIndex?: number;
  items: ITab[];
  onTabClick: TabsProps['onTabClick'];
};

const alignmentMap: Record<string, TabsAlignment | undefined> = {
  [Alignment.Left]: TabsAlignment.left,
  [Alignment.Center]: TabsAlignment.center,
  [Alignment.Right]: TabsAlignment.right,
};

export const TabLine: React.FC<TabLineProps> = ({
  activeTabIndex,
  items,
  onTabClick,
}) => {
  const { t } = useTranslation();
  const settings = useSettings();
  const { isMobile } = useEnvironment();

  const alignment = settings.get(settingsParams.resultsMenuAlignment);

  return (
    <Tabs
      className={st(classes.root, {
        mobileView: isMobile,
      })}
      activeTabIndex={activeTabIndex}
      alignment={alignmentMap[alignment] ?? TabsAlignment.left}
      aria-label={t('searchResults.tabs.menu.accessibilityLabel')}
      data-hook="tabs"
      items={items}
      onTabClick={onTabClick}
    />
  );
};
