import React, { FC } from 'react';
import { useEnvironment, useTranslation } from '@wix/yoshi-flow-editor';

import { useFormatters } from '../../../hooks';
import { stripMarkTags } from '../../stripMarkTags';
import { IForumListItemProps } from '../ForumListItem';
import StatsBar from './StatsBar';
import { classes, st } from './ForumListPostItem.st.css';

const ForumListPostItem: FC<IForumListItemProps> = ({ item, onLinkClick }) => {
  const { t } = useTranslation();
  const { isMobile, isEditorX } = useEnvironment();
  const { formatDate } = useFormatters();

  const styleStates = {
    mobileView: isMobile,
    fluid: isEditorX,
  };

  return (
    <a
      href={item.url}
      className={st(classes.root, styleStates)}
      data-hook="item-title"
      title={stripMarkTags(item.title)}
      onClick={(e) => onLinkClick?.(e, 'title')}
    >
      <div className={classes.itemContainer}>
        {/* @TODO add member data, when available */}
        {/* @TODO add has best answer checkmark before title, when available */}
        <div
          className={classes.title}
          dangerouslySetInnerHTML={{ __html: item.title }}
        />
        <div className={classes.subTitle}>
          <span>
            {t('searchResults.tabs.label.forums.inCategory', {
              category: item.categoryTitle,
            })}
          </span>
          {item.createdDate && (
            <>
              <span className={classes.dateSeparator}>·</span>
              <span>{formatDate(item.createdDate)}</span>
            </>
          )}
        </div>
        <div
          className={classes.description}
          dangerouslySetInnerHTML={{ __html: item.description }}
        />

        <StatsBar
          containerClassName={classes.statsContainer}
          likes={item.likeCount}
          comments={item.totalComments}
          views={item.viewCount}
        />
      </div>
    </a>
  );
};

export default ForumListPostItem;
